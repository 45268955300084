exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* $motion-primary: hsla(215, 100%, 65%, 1); #4C97FF */ /* #0F6BFF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .icon-button_container_278u5 {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n    -webkit-flex-direction: column;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    cursor: pointer;\n    font-size: 0.75rem;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    color: hsla(217, 100%, 53%, 1);\n    border-radius: 0.5rem;\n} .icon-button_container_278u5 + .icon-button_container_278u5 {\n    margin-top: 1.25rem;\n} .icon-button_title_36ChS {\n    margin-top: 0.5rem;\n    text-align: center;\n} .icon-button_disabled_2HD_J {\n    opacity: 0.5;\n    pointer-events: none;\n} .icon-button_container_278u5:active {\n    background-color: hsla(215, 100%, 65%, 0.15);\n}\n", ""]);

// exports
exports.locals = {
	"container": "icon-button_container_278u5",
	"title": "icon-button_title_36ChS",
	"disabled": "icon-button_disabled_2HD_J"
};